import { FC, Fragment, useEffect, useRef, useState } from "react";
import {
  CheckIcon,
  Squares2X2Icon,
  XMarkIcon,
} from "@heroicons/react/24/outline";

import { useLocation, useNavigate } from "react-router-dom";

import ButtonPrimary from "shared/Button/ButtonPrimary";
import DetailPagetLayout from "../Layout";
import { Checkbox, Dialog, DialogPanel, Transition } from "@headlessui/react";
import { formatDateTime, useData } from "data/data-provider";
import { CheckBadgeIcon } from "@heroicons/react/24/solid";
import toast from "react-hot-toast";
import { globalJson } from "global/global_json";
import { getBonzahProducts } from "api/bonzah/bonzah";
import { Booking } from "api/booking/booking";
import { CreateCanopyVerification } from "api/Canopy/canopy";
import { getInvoice } from "api/Invoice/invoice";
import { getProfileFun } from "api/profile/profile";
import { CreateStripeVerification } from "api/stripe/stripe";
import { CanopyPayload, StripePayload } from "api/types";

const ListingCarDetail: FC<{}> = () => {
  const [ignitionUrl, setIgnitionUrl] = useState<string | null>();
  const [axleloading, setAxleLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const thisPathname = useLocation().pathname;
  const navigate = useNavigate();

  const {
    selectedCar,
    user,
    stripeVerificationLoading,
    startDate,
    startHours,
    startMinutes,
    startPeriod,
    endDate,
    endHours,
    endMinutes,
    pickupLocation,
    setInvoice,
    extras,
    setExtras,
    selectedExtras,
    setSelectedExtras,
    bonzahProducts,
    setBookingdetails,
    setBonzahProducts,
    setSelectedBonzahProducts,
    selectedBonzahProducts,
    invoice,
    dropOffLocation,
    searchedPickupLoction,
    searchedDropoffLocation,
    sameDropoffLocation,
    endPeriod,
    setStripeVerificationLoading,
    setUser,
  } = useData();

  const userRef = useRef(user);
  let [bonzahProductsLoading, setBonzahProductsLoading] = useState(false);
  let [isOpen, setIsOpen] = useState(false);
  const [userData, setUserData] = useState<any>();
  function open() {
    setIsOpen(true);
  }

  const fetchData = async () => {
    try {
      const data = await getProfileFun();
      setUserData(data);
    } catch (error) {
      console.error("Error fetching profile data", error);
    }
  };

  useEffect(() => {
    const fetchBonzahProducts = async () => {
      setBonzahProductsLoading(true);
      const payload = {
        travelDetails: {
          departureDate: formatDateTime(
            startDate,
            startHours,
            startMinutes,
            startPeriod
          ),
          returnDate: formatDateTime(endDate, endHours, endMinutes, endPeriod),
        },
        company: globalJson.company_id,
      };

      const { data, error } = await getBonzahProducts(payload);

      if (data) {
        setBonzahProducts(data?.products);
        setBonzahProductsLoading(false);
      } else if (error) {
        toast.error(error);
        setBonzahProductsLoading(false);
      }
    };
    fetchData();
    fetchBonzahProducts();
  }, []);

  const cdw = bonzahProducts.find(
    (item: any) => item?.productType === "CdwByAtig"
  );
  const rcli = bonzahProducts.find((item: any) => item.productType === "Rlp");
  const sli = bonzahProducts.find((item: any) => item.productType === "Sli");
  const pai = bonzahProducts.find((item: any) => item.productType === "Pai");

  useEffect(() => {
    const fetchInvoice = async () => {
      const payload = {
        customer: user?.id,
        fleet: selectedCar?.id,
        pick_up_time: formatDateTime(
          startDate,
          startHours,
          startMinutes,
          startPeriod
        ),
        drop_off_time: formatDateTime(endDate, endHours, endMinutes, endPeriod),
        pick_up_location: pickupLocation?.id,
        drop_off_location:
          sameDropoffLocation === false
            ? dropOffLocation?.id
            : pickupLocation?.id,
        custom_pick_up_address: searchedPickupLoction,
        custom_drop_off_address:
          sameDropoffLocation === false
            ? searchedDropoffLocation
            : searchedPickupLoction,
        same_drop_off_address: sameDropoffLocation,
        extras: Array.from(selectedExtras),
        bonzah_insurance_options: Array.from(selectedBonzahProducts),
      };
      const data = await getInvoice(payload);
      setInvoice(data);
    };
    fetchInvoice();
  }, [selectedExtras, selectedBonzahProducts]);

  function close() {
    setIsOpen(false);
  }

  useEffect(() => {
    setSelectedExtras(new Set());
    setSelectedBonzahProducts(new Set());
  }, []);

  const handleBooking = async () => {
    try {
      const payload = {
        customer: user?.id,
        fleet: selectedCar?.id,
        pick_up_time: formatDateTime(
          startDate,
          startHours,
          startMinutes,
          startPeriod
        ),
        drop_off_time: formatDateTime(endDate, endHours, endMinutes, endPeriod),
        pick_up_location: pickupLocation?.id,
        drop_off_location:
          sameDropoffLocation === false
            ? dropOffLocation?.id
            : pickupLocation?.id,
        custom_pick_up_address: searchedPickupLoction,
        custom_drop_off_address:
          sameDropoffLocation === false
            ? searchedDropoffLocation
            : searchedPickupLoction,
        same_drop_off_address: sameDropoffLocation,
        extras: Array.from(selectedExtras),
        bonzah_insurance_options: Array.from(selectedBonzahProducts),
      };
      const data = await Booking(payload);
      setBookingdetails(data);
    } catch (error) {
      console.error("Error calling createBooking:", error);
    }
  };

  const handleClick = async () => {
    setStripeVerificationLoading(true);
    try {
      const newUserData = await getProfileFun();
      setUser((prevUser: any) => {
        const updatedUser = {
          ...prevUser,
          identity_verified: newUserData?.data?.identity_verified,
          insurance_verified: newUserData?.data?.insurance_verified,
        };

        // Update userRef
        userRef.current = updatedUser;

        // Safely update localStorage
        const storedUserString = localStorage.getItem("user");
        if (storedUserString) {
          const storedUser = JSON.parse(storedUserString);
          storedUser.identity_verified = updatedUser.identity_verified;
          localStorage.setItem("user", JSON.stringify(storedUser));
        }

        return updatedUser;
      });

      // Delayed execution for any UI effects like loading spinners
      setTimeout(() => {
        // Check ignitionUrl and verify identity or insurance status
        if (ignitionUrl && ignitionUrl.includes("https://verify.stripe.com")) {
          if (userRef.current?.identity_verified === true) {
            toast.success("Identity Verification Successful!");
          } else {
            toast.error("Identity Verification Failed. Please try again.");
          }
        } else {
          if (userRef.current?.insurance_verified === true) {
            toast.success("Insurance Verification Successful!");
          } else {
            toast.error("Insurance Verification Failed. Please try again.");
          }
        }
      }, 3000);
    } catch (error) {
      toast.error("Verification Failed! Please try again.");
    } finally {
      // Stop the loading spinner and close the verification modal
      setStripeVerificationLoading(false);
      close();
    }
  };

  useEffect(() => {
    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      e.preventDefault();
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    if (!pickupLocation) {
      navigate("/");
    }
  }, [pickupLocation]);

  const handleAxleVerificationButtonClick = async () => {
    setAxleLoading(true);
    if (user?.insurance_verified === false) {
      const payload: CanopyPayload = { user_id: user?.id ?? "" };
      try {
        const axleIgnitionResponse = (await CreateCanopyVerification(
          payload
        )) as any;

        setIgnitionUrl(axleIgnitionResponse?.data.insurance_verification_link);
        setAxleLoading(false);
        open();

        // Start polling the getProfileFun every 3 seconds
        const intervalId = setInterval(async () => {
          const newUserData = await getProfileFun();
          setUserData(newUserData);
          // Update user state
          setUser((prevUser: any) => ({
            ...prevUser,
            insurance_verified: newUserData?.data?.insurance_verified,
          }));

          // Instead of checking `user`, use `newUserData`
          if (newUserData?.data?.insurance_verified === true) {
            toast.success("Insurance Verification Successful!");
            close();
            clearInterval(intervalId);
          }
        }, 3000);
      } catch (error) {
        toast.error("Error starting axle ignition");
        setAxleLoading(false);
      } finally {
        setAxleLoading(false);
      }
    }
  };

  const handlePrimaryDriverVerificationButtonClick = async () => {
    setLoading(true);
    const payload: StripePayload = { customer_id: user?.id ?? "" };
    if (user?.identity_verified === false) {
      const stripeSessionResponse = (await CreateStripeVerification(
        payload
      )) as any;
      setIgnitionUrl(stripeSessionResponse?.data.session_url);
      setLoading(false);
      open();

      // Start polling the getProfileFun every 3 seconds
      const intervalId = setInterval(async () => {
        const newUserData = await getProfileFun();
        setUserData(newUserData);
        setUser((prevUser: any) => ({
          ...prevUser,
          identity_verified: newUserData?.data?.identity_verified,
        }));

        // If identity_verified becomes true, close the modal and clear the interval
        if (newUserData?.data?.identity_verified === true) {
          toast.success("Identity Verification Successful!");
          close();
          clearInterval(intervalId);
        }
      }, 3000);

      // Cleanup the interval when the modal closes
      return () => clearInterval(intervalId);
    }
  };

  const handleOpenModalImageGallery = () => {
    navigate(`${thisPathname}/?modal=PHOTO_TOUR_SCROLLABLE`);
  };

  const renderSection1 = () => {
    return (
      <div className=" !space-y-6">
        {/* 1 */}
        <div className="flex justify-between items-center">
          {/* <Badge
            color="pink"
            name={selectedCar?.vehicle?.vehicle_class?.name}
          /> */}
          {/* <LikeSaveBtns /> */}
        </div>

        {/* 2 */}
        <h2 className="text-2xl sm:text-3xl lg:text-4xl font-semibold">
          {selectedCar?.name}
        </h2>
      </div>
    );
  };

  const handleExtraChange = (extraId: any, checked: any) => {
    setSelectedExtras((prevSelectedExtras) => {
      const updatedExtras = new Set(prevSelectedExtras);
      if (checked) {
        updatedExtras.add(extraId);
      } else {
        updatedExtras.delete(extraId);
      }
      return updatedExtras;
    });
  };

  const handleBonzahProductChange = (
    isStandardProtection: boolean | null,
    productType: string | null = null
  ) => {
    setSelectedBonzahProducts((prevSelected) => {
      const updatedSet = new Set(prevSelected);

      const cdwType = "CdwByAtig"; // CDW product type
      const rcliType = "Rlp"; // RCLI product type

      if (isStandardProtection === true) {
        // Toggle Standard Protection: Add or Remove both CDW and RCLI
        if (updatedSet.has(cdwType) && updatedSet.has(rcliType)) {
          // If both are selected, unselect both
          updatedSet.delete(cdwType);
          updatedSet.delete(rcliType);
        } else {
          // Otherwise, select both CDW and RCLI
          updatedSet.add(cdwType);
          updatedSet.add(rcliType);
        }
      } else if (isStandardProtection === false) {
        // For Minimum Protection: Toggle only RCLI
        if (updatedSet.has(rcliType)) {
          updatedSet.delete(rcliType); // Unselect RCLI
        } else {
          updatedSet.add(cdwType); // Select RCLI
        }

        // Ensure CDW is not selected when Minimum Protection is selected
        updatedSet.delete(rcliType);
      } else if (productType) {
        // Handle Add-ons independently
        if (updatedSet.has(productType)) {
          updatedSet.delete(productType); // Unselect add-on
        } else {
          updatedSet.add(productType); // Select add-on
        }
      }

      return updatedSet;
    });
  };

  const renderExtrasSection = () => {
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <h2 className="text-2xl font-semibold text-white">Extras</h2>

        {/* CONTENT */}
        {selectedCar?.extras.map((extra) => (
          <div
            key={extra.id}
            className="w-full rounded-md border border-neutral-700 flex justify-between items-center p-5"
          >
            <div className="flex justify-start">
              <Checkbox
                checked={selectedExtras.has(extra.id)}
                onChange={(checked) => handleExtraChange(extra.id, checked)}
                className="group size-14 rounded-md  p-1 ring-1 ring-neutral-700 ring-inset data-[checked]:bg-primary-800 mr-4"
              >
                <CheckIcon
                  className={`size-12 stroke-rgba-stroke ${
                    selectedExtras.has(extra.id) ? "visible" : "invisible"
                  }`}
                />
              </Checkbox>
              <div>
                <div className="text-gray-800 dark:text-gray-200 lg:text-xl sm:text-l font-bold flex">
                  {extra.name}
                  {extra.recommended === 1 && (
                    <span className="bg-primary-100 text-white text-center rounded-md text-sm align-middle self-center p-1 ml-2">
                      Recommended
                    </span>
                  )}
                </div>
                <div className="text-gray-400 dark:text-neutral-400 lg:text-lg sm:text-md font-medium ">
                  {extra.description}
                </div>
              </div>
            </div>

            <div className="text-zinc-600 dark:text-white lg:text-4xl sm:2xl font-bold flex items-center xs:text-xl">
              ${extra.amount}/
              <div className="text-gray-400 dark:text-gray-200 lg:text-lg">
                {extra.nature === "per_day" ? "day" : "trip"}
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  };

  const renderPrimaryDriverVerificationSection = () => {
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <h2 className="lg:text-2xl sm:text-xl font-semibold flex justify-between items-center text-white">
          Primary Driver Verification
          {userData?.data?.identity_verified === true && (
            <CheckBadgeIcon className="fill-green-500 w-12 h-12" />
          )}
          {userData?.data?.identity_verified === false && (
            <ButtonPrimary onClick={handlePrimaryDriverVerificationButtonClick}>
              {loading ? (
                <div className="flex justify-center items-center col-span-full">
                  <div className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full border-t-transparent border-neutral-50"></div>
                </div>
              ) : (
                "Verify"
              )}
            </ButtonPrimary>
          )}{" "}
        </h2>
      </div>
    );
  };
  const renderBonzahSection = () => {
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <h2 className="text-xl font-medium text-neutral-400">
          Auto Insurance Verification
        </h2>

        <div className="w-full rounded-md border border-neutral-700 flex justify-between items-center p-5">
          <div className="flex justify-start">
            <div>
              <div className="text-gray-800 dark:text-white lg:text-xl sm:text-l font-bold">
                I have my own insurance
              </div>
            </div>
          </div>

          {userData?.data?.insurance_verified !== false ? (
            <CheckBadgeIcon className="fill-green-500 w-12 h-12" />
          ) : (
            <ButtonPrimary onClick={handleAxleVerificationButtonClick}>
              {axleloading ? (
                <div className="flex justify-center items-center col-span-full">
                  <div className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full border-t-transparent border-neutral-50"></div>
                </div>
              ) : (
                "Verify"
              )}
            </ButtonPrimary>
          )}
        </div>

        <div className="flex flex-col  justify-between items-start p-4 rounded-md border border-neutral-700 max-w-full ">
          {/* Left side content */}
          <div className="flex items-start">
            {/* Checkbox */}
            <Checkbox
              checked={
                selectedBonzahProducts?.has(cdw?.productType) &&
                selectedBonzahProducts?.has(rcli?.productType)
              }
              onChange={() => handleBonzahProductChange(true)}
              className="group size-7 rounded-md  p-1 ring-1 ring-neutral-700 ring-inset data-[checked]:bg-primary-800 mr-4"
            >
              <CheckIcon
                className={`size-5 ${
                  selectedBonzahProducts?.has(cdw?.productType) &&
                  selectedBonzahProducts?.has(rcli?.productType)
                    ? "visible"
                    : "invisible"
                }`}
              />
            </Checkbox>

            {/* Text Content */}
            <div>
              <div className="flex items-center mb-2">
                <h2 className="text-lg md:text-xl font-bold text-gray-900 dark:text-gray-100">
                  Standard Protection
                </h2>
                <button
                  className="ml-2 text-gray-500 dark:text-gray-400"
                  onClick={() =>
                    window.open(
                      "https://firebasestorage.googleapis.com/v0/b/gtluxerentals-5de60.appspot.com/o/bozah%2FRental%20Insurance.pdf?alt=media&token=38ec18ea-51d6-46e7-9ae6-8ecf86f5591c",
                      "_blank"
                    )
                  }
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="none"
                    viewBox="0 0 20 20"
                  >
                    <path
                      fill="#1B2430"
                      d="M9.997 18.958c-4.941 0-8.958-4.016-8.958-8.958s4.017-8.958 8.958-8.958c4.942 0 8.959 4.016 8.959 8.958s-4.017 8.958-8.959 8.958Zm0-16.666C5.747 2.292 2.29 5.75 2.29 10s3.458 7.708 7.708 7.708S17.706 14.25 17.706 10s-3.459-7.708-7.709-7.708Z"
                    />
                    <path
                      fill="#1B2430"
                      d="M10 11.458a.63.63 0 0 1-.625-.625V6.667A.63.63 0 0 1 10 6.042a.63.63 0 0 1 .625.625v4.166a.63.63 0 0 1-.625.625Zm-.003 2.709a.829.829 0 0 1-.316-.067.963.963 0 0 1-.275-.175.86.86 0 0 1-.175-.275.83.83 0 0 1-.067-.317.83.83 0 0 1 .067-.316c.041-.1.1-.192.175-.275a.96.96 0 0 1 .275-.175.833.833 0 0 1 .633 0c.1.041.192.1.275.175a.963.963 0 0 1 .175.275.83.83 0 0 1 .067.316.83.83 0 0 1-.067.317.86.86 0 0 1-.175.275.964.964 0 0 1-.275.175.83.83 0 0 1-.317.067Z"
                    />
                  </svg>
                </button>
              </div>

              <p className="text-sm text-gray-600 dark:text-gray-400">
                Combined CDW & RCLI
              </p>

              {/* CDW Details */}
              <div className="mt-2">
                <h3 className="font-semibold text-gray-700 dark:text-gray-300">
                  CDW
                </h3>
                <p className="text-sm text-gray-600 dark:text-gray-400">
                  Upto $35k
                  <br />
                  Primary Vehicle Insurance
                  <br />
                  Upto $500 Deductable
                </p>
              </div>

              {/* RCLI Details */}
              <div className="mt-2">
                <h3 className="font-semibold text-gray-700 dark:text-gray-300">
                  RCLI
                </h3>
                <p className="text-sm text-gray-600 dark:text-gray-400">
                  Bodily Injury Per Person $10K
                  <br />
                  Bodily Injury Aggregate $20K
                  <br />
                  Property Damage $10K
                </p>
              </div>
            </div>
          </div>

          {/* Right side content - Price */}
          <div className="mt-4 md:mt-0 w-full">
            <p className="text-2xl font-bold text-gray-900 dark:text-gray-100 flex justify-end items-center">
              ${cdw?.finalPrice + rcli?.finalPrice}/
              <span className="text-gray-400 dark:text-gray-200 lg:text-lg">
                day
              </span>
            </p>
          </div>
        </div>
        <div className="flex flex-col  justify-between items-start p-4 rounded-md border border-neutral-700 max-w-full ">
          {/* Left side content */}
          <div className="flex items-start">
            {/* Checkbox */}
            <Checkbox
              checked={
                selectedBonzahProducts?.has(cdw?.productType) &&
                !selectedBonzahProducts?.has(rcli?.productType)
              }
              onChange={() => handleBonzahProductChange(false)}
              className="group size-7 rounded-md  p-1 ring-1 ring-neutral-700 ring-inset data-[checked]:bg-primary-800 mr-4"
            >
              <CheckIcon
                className={`size-5 ${
                  selectedBonzahProducts?.has(cdw?.productType) &&
                  !selectedBonzahProducts?.has(rcli?.productType)
                    ? "visible"
                    : "invisible"
                }`}
              />
            </Checkbox>

            {/* Text Content */}
            <div>
              <div className="flex items-center mb-2">
                <h2 className="text-lg md:text-xl font-bold text-gray-900 dark:text-gray-100">
                  Minimum Protection
                </h2>
                <button
                  className="ml-2 text-gray-500 dark:text-gray-400"
                  onClick={() =>
                    window.open(
                      cdw.productInfo.descriptionOfCoverageUrl,
                      "_blank"
                    )
                  }
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="none"
                    viewBox="0 0 20 20"
                  >
                    <path
                      fill="#1B2430"
                      d="M9.997 18.958c-4.941 0-8.958-4.016-8.958-8.958s4.017-8.958 8.958-8.958c4.942 0 8.959 4.016 8.959 8.958s-4.017 8.958-8.959 8.958Zm0-16.666C5.747 2.292 2.29 5.75 2.29 10s3.458 7.708 7.708 7.708S17.706 14.25 17.706 10s-3.459-7.708-7.709-7.708Z"
                    />
                    <path
                      fill="#1B2430"
                      d="M10 11.458a.63.63 0 0 1-.625-.625V6.667A.63.63 0 0 1 10 6.042a.63.63 0 0 1 .625.625v4.166a.63.63 0 0 1-.625.625Zm-.003 2.709a.829.829 0 0 1-.316-.067.963.963 0 0 1-.275-.175.86.86 0 0 1-.175-.275.83.83 0 0 1-.067-.317.83.83 0 0 1 .067-.316c.041-.1.1-.192.175-.275a.96.96 0 0 1 .275-.175.833.833 0 0 1 .633 0c.1.041.192.1.275.175a.963.963 0 0 1 .175.275.83.83 0 0 1 .067.316.83.83 0 0 1-.067.317.86.86 0 0 1-.175.275.964.964 0 0 1-.275.175.83.83 0 0 1-.317.067Z"
                    />
                  </svg>
                </button>
              </div>

              {/* CDW Details */}
              <div className="mt-2">
                <h3 className="font-semibold text-gray-700 dark:text-gray-300">
                  CDW
                </h3>
                <p className="text-sm text-gray-600 dark:text-gray-400">
                  Upto $35k
                  <br />
                  Primary Vehicle Insurance
                  <br />
                  Upto $500 Deductable
                </p>
              </div>
            </div>
          </div>

          {/* Right side content - Price */}
          <div className="mt-4 md:mt-0 w-full">
            <p className="text-2xl font-bold text-gray-900 dark:text-gray-100 flex justify-end items-center">
              ${cdw?.finalPrice}/
              <span className="text-gray-400 dark:text-gray-200 lg:text-lg">
                day
              </span>{" "}
            </p>
          </div>
        </div>
        <h2 className="text-2xl font-semibold">Insurance Add-Ons</h2>
        {bonzahProducts?.find(
          (insuranceOption: any) =>
            insuranceOption.productType !== "CdwByAtig" &&
            insuranceOption.productType !== "Rlp"
        ) &&
          bonzahProducts?.map((insuranceOption: any) => {
            if (
              insuranceOption.productType !== "CdwByAtig" &&
              insuranceOption.productType !== "Rlp"
            ) {
              return (
                <div
                  key={insuranceOption.productType}
                  className="flex flex-col justify-between items-start p-4 rounded-md border border-neutral-700 max-w-full"
                >
                  {/* Left side content */}
                  <div className="flex items-start">
                    {/* Checkbox */}
                    <Checkbox
                      checked={selectedBonzahProducts?.has(
                        insuranceOption.productType
                      )}
                      onChange={() =>
                        handleBonzahProductChange(
                          null,
                          insuranceOption.productType
                        )
                      }
                      className="group size-7 rounded-md  p-1 ring-1 ring-neutral-700 ring-inset data-[checked]:bg-primary-800 mr-4"
                    >
                      <CheckIcon
                        className={`size-5 ${
                          selectedBonzahProducts?.has(
                            insuranceOption.productType
                          )
                            ? "visible"
                            : "invisible"
                        }`}
                      />
                    </Checkbox>

                    {/* Text Content */}
                    <div>
                      <div className="flex items-center mb-2">
                        <h2 className="text-lg md:text-xl font-bold text-gray-900 dark:text-gray-100">
                          {insuranceOption.name}
                        </h2>
                        <button
                          className="ml-2 text-gray-500 dark:text-gray-400"
                          onClick={() =>
                            window.open(
                              insuranceOption.productInfo
                                .descriptionOfCoverageUrl,
                              "_blank"
                            )
                          }
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            fill="none"
                            viewBox="0 0 20 20"
                          >
                            <path
                              fill="#1B2430"
                              d="M9.997 18.958c-4.941 0-8.958-4.016-8.958-8.958s4.017-8.958 8.958-8.958c4.942 0 8.959 4.016 8.959 8.958s-4.017 8.958-8.959 8.958Zm0-16.666C5.747 2.292 2.29 5.75 2.29 10s3.458 7.708 7.708 7.708S17.706 14.25 17.706 10s-3.459-7.708-7.709-7.708Z"
                            />
                            <path
                              fill="#1B2430"
                              d="M10 11.458a.63.63 0 0 1-.625-.625V6.667A.63.63 0 0 1 10 6.042a.63.63 0 0 1 .625.625v4.166a.63.63 0 0 1-.625.625Zm-.003 2.709a.829.829 0 0 1-.316-.067.963.963 0 0 1-.275-.175.86.86 0 0 1-.175-.275.83.83 0 0 1-.067-.317.83.83 0 0 1 .067-.316c.041-.1.1-.192.175-.275a.96.96 0 0 1 .275-.175.833.833 0 0 1 .633 0c.1.041.192.1.275.175a.963.963 0 0 1 .175.275.83.83 0 0 1 .067.316.83.83 0 0 1-.067.317.86.86 0 0 1-.175.275.964.964 0 0 1-.275.175.83.83 0 0 1-.317.067Z"
                            />
                          </svg>
                        </button>
                      </div>
                    </div>
                  </div>

                  {/* Right side content - Price */}
                  <div className="mt-4 md:mt-0 w-full">
                    <p className="text-2xl font-bold text-gray-900 dark:text-gray-100 flex justify-end items-center">
                      ${insuranceOption?.finalPrice}/
                      <span className="text-gray-400 dark:text-gray-200 lg:text-lg">
                        day
                      </span>
                    </p>
                  </div>
                </div>
              );
            }
            return null;
          })}
      </div>
    );
  };

  const renderSidebarPrice = () => {
    return (
      <div className="listingSectionSidebar__wrap shadow-xl">
        {/* PRICE */}

        <div className="w-full max-w-sm mx-auto">
          <h2 className="text-xl font-medium text-gray-900 dark:text-gray-100">
            Rental Bill
          </h2>
          <p className="mt-2 text-sm text-gray-500 dark:text-gray-400">
            Prices may change depending on the length of the rental and the
            price of your rental car.
          </p>
          <div className="mt-4">
            <div className="flex justify-between items-center">
              <div>
                <span className="text-lg font-normal text-gray-700 dark:text-gray-300">
                  Subtotal
                </span>
                <span className="text-sm text-gray-500 dark:text-gray-400">
                  {" "}
                  (${invoice?.avg_car_price_per_day} *{" "}
                  {invoice.trip_days + "days"})
                </span>
              </div>
              <span className="text-2xl font-medium text-gray-900 dark:text-gray-100">
                ${invoice.car_total}
              </span>
            </div>
          </div>
          {/* {selectedInsuranceProtection?.map((selectedBonzahOption, index) => (
            <div className="mt-4" key={index}>
              <div className="flex justify-between items-center">
                <div>
                  <span className="text-lg font-normal text-gray-700 dark:text-gray-300">
                    {selectedBonzahOption?.title ?? "Insurance Coverage"}{" "}
                  </span>
                  <span className="text-sm text-gray-500 dark:text-gray-400">
                    {" "}
                    ($
                    {((selectedBonzahOption?.price ?? 0) / 100).toFixed(
                      2
                    )} * {tripDays().toFixed(2)}
                    days)
                  </span>
                </div>
                <span className="text-2xl font-medium text-gray-900 dark:text-gray-100">
                  $
                  {(
                    ((selectedBonzahOption?.price ?? 0) / 100) *
                    tripDays()
                  ).toFixed(2)}
                </span>
              </div>
            </div>
          ))} */}
          {invoice?.location_total !== "0.00" && (
            <div className="mt-4">
              <div className="flex justify-between items-center">
                <span className="text-lg font-normal text-gray-700 dark:text-gray-300">
                  Location Total
                </span>
                <span className="text-2xl font-medium text-gray-900 dark:text-gray-100">
                  ${invoice.location_total}
                </span>
              </div>
            </div>
          )}

          {invoice?.extras_total !== "0.00" && (
            <div className="mt-4">
              <div className="flex justify-between items-center">
                <span className="text-lg font-normal text-gray-700 dark:text-gray-300">
                  Extras Total
                </span>
                <span className="text-2xl font-medium text-gray-900 dark:text-gray-100">
                  ${invoice.extras_total}
                </span>
              </div>
            </div>
          )}

          {invoice?.insurance_total !== "0.00" && (
            <div className="mt-4">
              <div className="flex justify-between items-center">
                <span className="text-lg font-normal text-gray-700 dark:text-gray-300">
                  Insurance Total
                </span>
                <span className="lg:text-2xl sm:text-xl font-medium text-gray-900 dark:text-gray-100">
                  ${invoice.insurance_total}
                </span>
              </div>
            </div>
          )}

          {invoice?.tax !== "0.00" && (
            <div className="mt-4">
              <div className="flex justify-between items-center">
                <span className="text-lg font-normal text-gray-700 dark:text-gray-300">
                  Tax
                </span>
                <span className="text-2xl font-medium text-gray-900 dark:text-gray-100">
                  ${invoice.tax}
                </span>
              </div>
            </div>
          )}

          {invoice?.discount !== "0.00" && (
            <div className="mt-4">
              <div className="flex justify-between items-center">
                <span className="text-lg font-normal text-gray-700 dark:text-gray-300">
                  Discount
                </span>
                <span className="text-2xl font-medium text-gray-900 dark:text-gray-100">
                  ${invoice.discount}
                </span>
              </div>
            </div>
          )}

          <div className="mt-8 border-t border-gray-200 dark:border-gray-700 pt-4">
            <div className="flex justify-between items-center">
              <div>
                <span className="text-lg font-normal text-gray-700 dark:text-gray-300">
                  Total Rental Price
                </span>
                <p className="mt-1 text-sm text-gray-500 dark:text-gray-400">
                  Overall price rental
                </p>
              </div>
              <div>
                <span className="text-2xl font-medium text-gray-900 dark:text-gray-100">
                  ${invoice.total_price_without_security}
                </span>
              </div>
            </div>
          </div>

          {invoice.security_deposit_total !== "0.00" && (
            <div className="mt-4">
              <div className="flex justify-between items-center">
                <span className="text-lg font-normal text-gray-700 dark:text-gray-300">
                  Deposit Fee
                </span>
                <span className="text-2xl font-medium text-gray-900 dark:text-gray-100">
                  ${invoice.security_deposit_total}
                </span>
              </div>
            </div>
          )}
        </div>

        {/* SUBMIT */}
        <ButtonPrimary
          className="hidden lg:block"
          onClick={() => {
            if (userData?.identity_verified === false) {
              toast.error(
                "Please complete stripe verification to move forward"
              );
              return;
            }
            if (
              !userData?.data?.insurance_verified &&
              !(
                selectedBonzahProducts?.has("CdwByAtig") ||
                selectedBonzahProducts?.has("rlp")
              )
            ) {
              toast.error(
                "Either verify your insurance with canopy or select an inurance coverage option"
              );
            } else {
              handleBooking();
              navigate("/checkout");
            }
          }}
        >
          Reserve
        </ButtonPrimary>
      </div>
    );
  };

  const renderSidebarDetail = () => {
    function formatDate(date: Date) {
      const dayNameFormat = new Intl.DateTimeFormat("en-US", {
        weekday: "long",
      });
      const monthDayFormat = new Intl.DateTimeFormat("en-US", {
        month: "long",
        day: "numeric",
      });
      const timeFormat = new Intl.DateTimeFormat("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      });

      const dayName = dayNameFormat.format(date);
      const monthDay = monthDayFormat.format(date);
      const time = timeFormat.format(date);

      return `${dayName}, ${monthDay} · ${time}`;
    }
    return (
      <div className="listingSection__wrap lg:shadow-xl">
        <span className="text-2xl font-semibold block">
          Pick up and drop off
        </span>
        <div className="mt-8 flex">
          <div className="flex-shrink-0 flex flex-col items-center py-2">
            <span className="block w-6 h-6 rounded-md border border-neutral-400"></span>
            <span className="block flex-grow border-l border-neutral-400 border-dashed my-1"></span>
            <span className="block w-6 h-6 rounded-md border border-neutral-400"></span>
          </div>
          <div className="ml-4 space-y-14 text-sm">
            <div className="flex flex-col space-y-2">
              <span className=" text-neutral-500 dark:text-neutral-400">
                {formatDate(startDate)}
              </span>
              <span className=" font-semibold">{pickupLocation?.name}</span>
            </div>
            <div className="flex flex-col space-y-2">
              <span className=" text-neutral-500 dark:text-neutral-400">
                {formatDate(endDate)}
              </span>
              <span className=" font-semibold">
                {dropOffLocation?.name ?? pickupLocation?.name}
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className={` nc-ListingCarDetailPage `}>
        {/* SINGLE HEADER */}
        <header className="rounded-md sm:rounded-md">
          <div className="relative grid grid-cols-4 gap-1 sm:gap-2">
            <div
              className="col-span-2 row-span-2 relative rounded-md sm:rounded-md overflow-hidden cursor-pointer"
              onClick={handleOpenModalImageGallery}
            >
              <img
                src={
                  (selectedCar?.fleet_photos?.length ?? 0) > 0
                    ? `https://fleet-management-images-upload-be.s3.amazonaws.com/${selectedCar?.fleet_photos[0].photo_url}`
                    : "https://tenant.fleetwire.io/images/vehicle-placeholder.jpg"
                }
                alt="0"
                className="absolute inset-0 object-cover rounded-md sm:rounded-md w-full h-full"
                sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 50vw"
              />
              <div className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity"></div>
            </div>

            {/*  */}
            <div
              className="col-span-1 row-span-2 relative rounded-md sm:rounded-md overflow-hidden cursor-pointer"
              onClick={handleOpenModalImageGallery}
            >
              <img
                className="absolute inset-0 object-cover rounded-md sm:rounded-md w-full h-full"
                src={
                  (selectedCar?.fleet_photos?.length ?? 0) > 1
                    ? `https://fleet-management-images-upload-be.s3.amazonaws.com/${selectedCar?.fleet_photos[1].photo_url}`
                    : "https://tenant.fleetwire.io/images/vehicle-placeholder.jpg"
                }
                alt="1"
                sizes="400px"
              />
              <div className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity"></div>
            </div>

            {/*  */}
            {selectedCar?.fleet_photos
              .filter((_, i) => i >= 2 && i < 4)
              .map((item, index) => (
                <div
                  key={index}
                  className={`relative rounded-md sm:rounded-md overflow-hidden ${
                    index >= 2 ? "block" : ""
                  }`}
                >
                  <div className="aspect-w-4 aspect-h-3">
                    <img
                      className="absolute inset-0 object-cover w-full h-full rounded-md sm:rounded-md "
                      src={
                        `https://fleet-management-images-upload-be.s3.amazonaws.com/${item.photo_url}` ||
                        ""
                      }
                      alt="photos"
                      sizes="400px"
                    />
                  </div>

                  {/* OVERLAY */}
                  <div
                    className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity cursor-pointer"
                    onClick={handleOpenModalImageGallery}
                  />
                </div>
              ))}

            <div
              className="absolute hidden md:flex md:items-center md:justify-center left-3 bottom-3 px-4 py-2 rounded-md bg-neutral-100 text-neutral-500 cursor-pointer hover:bg-neutral-200 z-10"
              onClick={handleOpenModalImageGallery}
            >
              <Squares2X2Icon className="h-5 w-5" />

              <span className="ml-2 text-neutral-800 text-sm font-medium">
                Show all photos
              </span>
            </div>
          </div>
        </header>

        {/* MAIn */}
        <main className=" relative z-10 mt-11 flex flex-col lg:flex-row ">
          {/* CONTENT */}
          <div className="w-full lg:w-3/5 xl:w-2/3 space-y-8 lg:pr-10 lg:space-y-10">
            {renderSection1()}
            <div className="block lg:hidden">{renderSidebarDetail()}</div>
            {renderPrimaryDriverVerificationSection()}
            {/* {renderAxleSection()} */}
            {renderBonzahSection()}
            {renderExtrasSection()}
          </div>

          {/* SIDEBAR */}
          <div className="block flex-grow mt-0 lg:mt-0">
            {/* {renderSidebarDetail()} */}
            <div className="hidden lg:block mt-10 sticky top-28">
              {renderSidebarPrice()}
            </div>
          </div>
        </main>
      </div>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="HeroSearchFormMobile__Dialog relative z-50"
          onClose={open}
          static={true}
        >
          <div className="fixed inset-0 bg-neutral-100 dark:bg-neutral-900">
            <div className="flex h-full">
              <Transition
                as={Fragment}
                enter="ease-out transition-transform"
                enterFrom="opacity-0 translate-y-52"
                enterTo="opacity-100 translate-y-0"
                leave="ease-in transition-transform"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-52"
              >
                <DialogPanel className="relative h-full w-full overflow-hidden flex-1 flex flex-col justify-between ">
                  <>
                    <div className="absolute left-4 top-4">
                      <button
                        className="focus:outline-none focus:ring-0"
                        onClick={close}
                      >
                        <XMarkIcon
                          onClick={handleClick}
                          className="w-5 h-5 text-black dark:text-white"
                        />
                      </button>
                    </div>

                    <div className="flex-1 pt-12 p-1 flex flex-col overflow-auto">
                      <div className="flex-1 flex flex-col bg-white dark:bg-neutral-800">
                        <div className="flex-1 flex flex-col transition-opacity animate-[myblur_0.4s_ease-in-out] overflow-auto">
                          <div className="p-5 ">
                            <span className="block font-semibold text-xl sm:text-2xl">
                              Verification
                            </span>
                          </div>
                          <div className="flex-1 relative flex z-10 ">
                            <div className="grid justify-center w-full overflow-hidden rounded-md ">
                              <iframe
                                title="Verification"
                                width="100%"
                                height="100%"
                                className="w-full h-full"
                                src={ignitionUrl ?? ""}
                                allow={"camera " + ignitionUrl}
                                allowFullScreen
                              ></iframe>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <div className="px-4 py-3 bg-white dark:bg-neutral-900 border-t border-neutral-200 dark:border-neutral-700 flex justify-between">
                      <div></div>
                      <ButtonPrimary
                        sizeClass="px-6 py-3 !rounded-md"
                        onClick={() => {
                          close();
                          callFleetwireCustomerAPI();
                        }}
                      >
                        Done
                      </ButtonPrimary>
                    </div> */}
                  </>
                </DialogPanel>
              </Transition>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default function ListingCarDetailPage() {
  return (
    <DetailPagetLayout>
      <ListingCarDetail />
    </DetailPagetLayout>
  );
}
