import { globalJson } from "global/global_json";

const AUTH_TOKEN = globalJson?.authToken;
const MAPS_KEY = globalJson?.mapKey;

const getHeaders = () => ({
  Accept: "application/json",
  Authorization: AUTH_TOKEN,
});

async function handleResponse<T>(response: Response): Promise<T> {
  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(
      errorData.message || errorData.detail || "Server responded with an error"
    );
  }
  const data: unknown = await response.json();
  // Here you might want to add runtime type checks depending on what T could be
  return data as T; // This assumes that the calling code knows what type it expects.
}

export const searchLocations = async (input: string) => {
  const response = await fetch(
    `https://api-map.1now.app/toco_backend/google_maps_autocomplete/?input=${input}`,
    {
      headers: getHeaders(),
    }
  );
  return handleResponse(response);
};

export const getNearbyLocations = async (placeId: string) => {
  const response = await fetch(
    `https://api-map.1now.app/toco_backend/check_location_in_range/?place_id=${placeId}&key=${MAPS_KEY}`
  );
  return handleResponse(response);
};

export const getLocationFromLatLong = async (latLng: string) => {
  const response = await fetch(
    `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latLng}&sensor=true&key=${MAPS_KEY}`
  );
  return handleResponse(response);
};

export const getCompanyData = async () => {
  const response = await fetch(
    `https://tenant.fleetwire.io/api/v1/company/${globalJson.companyId}?include=deliveryLocations,taxCategories`,
    {
      headers: getHeaders(),
    }
  );
  return handleResponse(response);
};

export const sendNewsLetterEmail = async (
  email: string,
  name: string,
  message: string
) => {
  const serviceId = globalJson?.util_serviceId;
  const templateId = globalJson?.util_templateId;
  const userId = globalJson?.util_userId;
  const privateKey = globalJson?.util_privateKey;

  await fetch("https://api.emailjs.com/api/v1.0/email/send", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Origin: "https://dashboard.emailjs.com",
    },
    body: JSON.stringify({
      service_id: serviceId,
      template_id: templateId,
      user_id: userId,
      accessToken: privateKey,
      template_params: { email, name, message },
    }),
  });
};

export const getStripeVerificationDetails = async (document_vr_id: string) => {
  const response = await fetch(
    `https://api-dev.1now.app/api/users/stripe-verification-report/`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        verification_id: document_vr_id,
        token: globalJson.adminEmailToken,
      }),
    }
  );
  return handleResponse(response);
};
