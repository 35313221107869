import clientSay1 from "images/clientSay1.png";
import clientSay2 from "images/clientSay2.png";
import clientSay3 from "images/clientSay3.png";
import clientSay4 from "images/clientSay4.png";

export const globalJson = {
  company_id: 1,

  // apiUtil auth header key
  authToken: "Bearer 163|ka3eRj4p0zYF4kNG88qHBUCQ7XIbs2TKsljcvmN8",
  companyId: "9ff8bede-1fb2-4655-8426-76fee94bbcb3",
  stripePublishableKey:
    "pk_live_51PVw6BJ18cXCKvET4kWb94orKdFmzmdK030u0IxKG8kGkb79dwioRSaLOrxJUta2WiJACt5uiNCZsNsUVHHeiIrU00K00PmkLB",
  mapKey: "AIzaSyDKEntg7JydrxI7zr2tQL5OYcS5M-er-bc",
  adminPhone: "1 (515) 664-6846",
  residenceStateCode: "MI",

  //email confirmation admin token
  adminEmailToken:
    "cfndPpHh9z5FYuKPJhTB5pzWv7FJMgH2hNBpaoagmGHB14TVUprZDKlUrOmtCzH9d8USbWZcdl6TMfyLvjHuoGwmvD0KnpyT1EEup3V0uOru6wgDeaHxVAAkWB3NTYNi",
  websiteLink: "https://places.rentals",
  sender: "places",
  ccEmail: "placescarrentals@gmail.com",

  // apiUtil sendNewsLetterEmail
  util_serviceId: "service_x6xxdfr",
  util_templateId: "template_u5pyime",
  util_userId: "-JjUx23fQtDCNRtB7",
  util_privateKey: "fbkSe9TkVKJZKl2tZEccs",

  // apiUtil getBonzahToken
  client_secret_dev:
    "mJndwz3vCyeqIA+NTYX9+k60d7qVT03U5fwBX+UJUo1jVayg9+5Kd5Gq4+70kaB3n7yXSDyqRJVt6oF6LNAvzzdbQ0aEcqBH1c6z7r/3/QkL0GrIC5alkXj41oE8iMavB6UXVQ==",
  client_id_dev:
    "45818bba-d761-431b-b062-069f091b966b-20240628532913-vendor@bonzah.com",
  client_secret_prod:
    "6ZMNNhnCiqkEyqtWadXN12Fkr6kIboolgNvBJwtZyDZ4XQBvPDp/C2TtXbXyS0UsZ+2ocXmfdO14D3GMi6VhcWEY8hrXXePAf0od6VExhuyNOsipKrsxuBzha32Du0GT4fzvpA==",
  client_id_prod:
    "65f14daa-d927-4331-9f32-c4ab71857dc1-20240628560928-vendor@bonzah.com",

  agreement_key:
    "https://firebasestorage.googleapis.com/v0/b/places-d6aeb.appspot.com/o/agreements%2F",
  bookingId_key: ".pdf?alt=media&token=ebaa324e-e15e-4b53-a844-eda0d06afab7",

  // bg_config
  firebaseConfig: {
    apiKey: "AIzaSyBiuXNNY0Jmrlss3sYGHztifS0W2l3bOxY",
    authDomain: "places-d6aeb.firebaseapp.com",
    projectId: "places-d6aeb",
    storageBucket: "places-d6aeb.appspot.com",
    messagingSenderId: "480059066196",
    appId: "1:480059066196:web:66f70df5a3144a965beac1",
    measurementId: "G-HTWZZZE659",
  },

  about_us_para: `At Places Rentals, we are transforming the way Florida does Car Rentals. We don’t believe in waiting in lines. We don’t believe in charging complex fees or surprise charges after the trip. We pride ourselves in providing a seamless car rental experience for each and every situation. 

With our custom built booking software, you can book a car, verify your documents and pickup the vehicle all in one seamless flow. No long lines, no messy paperwork. Show up, get the key and you’re on your way in 5 minutes or less. `,

  clientSays_arr: [
    {
      id: 1,
      clientName: "Rashad M",
      clientAddress: "Escalade",
      content:
        "By far the best car rental experience I’ve ever had. Paperwork signed virtually allowing me to show up and get the key out of the lockbox and I was on my way!",
      img: clientSay1,
    },
    {
      id: 2,
      clientName: "Ricardo F",
      clientAddress: "Hellcat Charger",
      content:
        "The car is a BEAST! The team at Places Rentals made this experience top tier. Delivered the car right to my door step, and by far the best pricing in the area. These guys are the best in town",
      img: clientSay2,
    },
    {
      id: 3,
      clientName: "Ben A",
      clientAddress: "Passat",
      content:
        "Couldn’t believe the monthly pricing on this vehicle! Best in town and will be back.",
      img: clientSay4,
    },
    {
      id: 4,
      clientName: "Rachel L",
      clientAddress: "Mini Van",
      content:
        "Traveling with kids is always a hassle but I’ve never had a car rental experience this seamless in all of my 30 years. We showed up, got the kids in the van and were on our way. If you’re going to Deerfield beach, this is the best in town!",
      img: clientSay3,
    },
  ],

  //social links
  facebook: "https://www.facebook.com",
  instagram: "https://www.instagram.com/alexprofits/",

  //contact
  address: "Deerfield Beach, FL 33441",
  addressLink:
    "https://www.google.com/maps/place/150+SE+5th+Ct,+Deerfield+Beach,+FL+33441,+USA/data=!4m2!3m1!1s0x88d91d7f0ab93afd:0x8789e11613ba111b?sa=X&ved=1t:242&ictx=111",
  phone: "+1 (515) 664-6846",
  email: "Contact@places.rentals",
};

export const scriptHubSpot = document.createElement("script");
scriptHubSpot.src = "//js-na1.hs-scripts.com/46572950.js";
scriptHubSpot.async = true;
scriptHubSpot.defer = true;
scriptHubSpot.id = "hs-script-loader";
